import { useFeatureFlagDetail } from '@hackler/react-sdk'
import { CurrentUser } from '../model/Common/common.interface'

type HackleFlagKey = typeof HACKLE_FLAG_KEY
type HackleFlagValue = HackleFlagKey[keyof HackleFlagKey]
export enum HACKLE_A_B_TEST_KEY {
  PROMOTION_2023_8_PRICE = 22,
  TRIAL_BANNER_DESIGN_CHANGE_2024_02 = 24,
  TRIAL_WELCOME_BOTTOM_BUTTON_DESIGN_CHANGE_2024_02 = 25,
  HOME_BODY_SECTION_ORDER_CHANGE_2024_02 = 26,
  RETENTION_RECOMMEND_TIME_AND_TUTOR = 29,
  PRICING_MOBILE_HERO_TEST = 30,
  RETENTION_ONE_CLICK_HOME = 35,
  RETENTION_ONE_CLICK_AFTER_LESSON = 36,
  RETENTION_ONE_CLICK_AFTER_CAF = 37,
  LESSON_ONBOARDING = 38,
  PROMOTION_LABEL_N_WON = 41,
}
export enum HACKLE_FLAG_KEY {
  SUMMER_PROMOTION = 25,
  TODO_LIST_VIDEO = 27,
  WELCOME_PAGE_TRIAL_BUTTON = 26,
  MOVE_LESSON_COMPLETE_PAGE = 28,
  CONVERSATION_WEBVIEW_PAGE = 30,
  CONVERSATION_ROLEPLAY = 32,
  CONVERSATION_PROGRESS_BAR = 34,
  WEBINAR_INTERNATIONAL_PAYMENT = 37,
  NEW_AI_TUTOR = 40,
  CHANGE_DAILY_ACTIVITY = 41,
}

export const HACKLE_FALLBACK_VALUES = {
  ...Object.values(HACKLE_FLAG_KEY).map((key) => ({ [key]: false })),
  // 여기에 Hackle이 Offline이거나 에러발생시에도 사용할 기능들을 추가
}
export const useFeatureFlag = (featureNumber: HackleFlagValue) => {
  const { isOn, reason } = useFeatureFlagDetail(featureNumber)

  /**
   * https://docs-kr.hackle.io/docs/react-feature-flag
   * DEFAULT_RULE: 개별 타겟팅, 사용자 타겟팅 중 어디에도 매치 되지 않았습니다.
   * INVALID_INPUT: (예: 숫자 타입을 넣어야 하는 파라미터에 문자 타입 입력)
   */
  const invalidReasons = ['SDK_NOT_READY', 'FEATURE_FLAG_NOT_FOUND', 'EXCEPTION', 'INVALID_INPUT']
  const isInvalid = invalidReasons.findIndex((v) => v === reason) >= 0
  return isInvalid ? HACKLE_FALLBACK_VALUES[featureNumber] : isOn
}
/**
 * 해클에 넘길 유저 관련 데이터를 생성하는 함수
 * @param userData CurrentUser
 * @returns Hackle용 유저 데이터ㄴ
 */
export const getHackleUserData = (
  userData: CurrentUser,
  prefTutosCount: number
): Record<string, string | number | object> | undefined => {
  if (userData) {
    return {
      userId: `${userData.id}`,
      properties: {
        userId: userData.id,
        preferenced_tutors: prefTutosCount > 0,
        entered_lesson_count: userData.profile.entered_lesson_count,
        finished_lesson_count: userData.profile.finished_lesson_count,
        has_entered_lesson: userData.profile.entered_lesson_count > 0,
        has_finished_lesson: userData.profile.finished_lesson_count > 0,
        upcomingLessonCount: userData.upcoming_lesson_count,
        unusedPaidCoupons: userData.unused_coupons,
        isPaidUser: userData.profile.total_amount > 0,
        isRingleMember: userData.is_ringle_member,
        locale: userData.locale,
        isMulticampusMember: userData.is_multicampus_member,
        isNoLessonTrialUser: userData.upcoming_lesson_count == 0 && userData.trial_completed == false,
        customerType: userData?.braze?.private?.customer_type,
        isFirstBenefitUser: userData?.first_purchase_benefit_available,
      },
    }
  } else {
    return undefined
  }
}
