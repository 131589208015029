export { ReactComponent as QIcon3book } from './lib/q_3book.svg'
export { ReactComponent as QIcon_alert } from './lib/q_alert.svg'
export { ReactComponent as QIcon_announce } from './lib/q_announce.svg'
export { ReactComponent as QIcon_arrow_bold_left } from './lib/q_arrow_bold_left.svg'
export { ReactComponent as QIcon_arrow_bold_right } from './lib/q_arrow_bold_right.svg'
export { ReactComponent as QIcon_arrow_left } from './lib/q_arrow_left.svg'
export { ReactComponent as QIcon_arrow_right } from './lib/q_arrow_right.svg'
export { ReactComponent as QIcon_bar_graph } from './lib/q_bar_graph.svg'
export { ReactComponent as QIcon_bell_active } from './lib/q_bell_active.svg'
export { ReactComponent as QIcon_bell_default } from './lib/q_bell_default.svg'
export { ReactComponent as QIcon_bell_on } from './lib/q_bell_on.svg'
export { ReactComponent as QIcon_book } from './lib/q_book.svg'
export { ReactComponent as QIcon_bookmark } from './lib/q_bookmark.svg'
export { ReactComponent as QIcon_calendar_check } from './lib/q_calendar_check.svg'
export { ReactComponent as QIcon_calendar_filled } from './lib/q_calendar_filled.svg'
export { ReactComponent as QIcon_calendar_plus } from './lib/q_calendar_plus.svg'
export { ReactComponent as QIcon_camera_default } from './lib/q_camera_default.svg'
export { ReactComponent as QIcon_camera_off } from './lib/q_camera_off.svg'
export { ReactComponent as QIcon_cart } from './lib/q_cart.svg'
export { ReactComponent as QIcon_certified } from './lib/q_certified.svg'
export { ReactComponent as QIcon_chat } from './lib/q_chat.svg'
export { ReactComponent as QIcon_materials } from './lib/q_materials.svg'
// design system checkbox에 들어가는 체크박스 아이콘
export { ReactComponent as QIcon_check_default } from './lib/q_check_default.svg'
export { ReactComponent as QIcon_check_filled } from './lib/q_check_filled.svg'
export { ReactComponent as QIcon_check_mark } from './lib/q_check_mark.svg'
export { ReactComponent as QIcon_check_outline } from './lib/q_check_outline.svg'
export { ReactComponent as QIcon_checkbox_icon } from './lib/q_checkbox_icon.svg'
export { ReactComponent as QIcon_chevron_bold_down } from './lib/q_chevron_bold_down.svg'
export { ReactComponent as QIcon_chevron_bold_left } from './lib/q_chevron_bold_left.svg'
export { ReactComponent as QIcon_chevron_bold_right } from './lib/q_chevron_bold_right.svg'
export { ReactComponent as QIcon_chevron_bold_up } from './lib/q_chevron_bold_up.svg'
export { ReactComponent as QIcon_chevron_double_left } from './lib/q_chevron_double_left.svg'
export { ReactComponent as QIcon_chevron_double_right } from './lib/q_chevron_double_right.svg'
export { ReactComponent as QIcon_chevron_down } from './lib/q_chevron_down.svg'
export { ReactComponent as QIcon_chevron_left } from './lib/q_chevron_left.svg'
export { ReactComponent as QIcon_chevron_right } from './lib/q_chevron_right.svg'
export { ReactComponent as QIcon_chevron_up } from './lib/q_chevron_up.svg'
export { ReactComponent as QIcon_clock } from './lib/q_clock.svg'
export { ReactComponent as QIcon_close_bold } from './lib/q_close_bold.svg'
export { ReactComponent as QIcon_close_default } from './lib/q_close_default.svg'
export { ReactComponent as QIcon_close_filled } from './lib/q_close_filled.svg'
export { ReactComponent as QIcon_close_outline } from './lib/q_close_outline.svg'
export { ReactComponent as QIcon_computer } from './lib/q_computer.svg'
export { ReactComponent as QIcon_coupon } from './lib/q_coupon.svg'
export { ReactComponent as QIcon_credit } from './lib/q_credit.svg'
export { ReactComponent as QIcon_discount } from './lib/q_discount.svg'
export { ReactComponent as QIcon_dollar } from './lib/q_dollar.svg'
export { ReactComponent as QIcon_download } from './lib/q_download.svg'
export { ReactComponent as QIcon_effect } from './lib/q_effect.svg'
export { ReactComponent as QIcon_exclamation } from './lib/q_exclamation.svg'
export { ReactComponent as QIcon_exclamation_filled } from './lib/q_exclamation_filled.svg'
export { ReactComponent as QIcon_exclamation_filled2 } from './lib/q_exclamation_filled2.svg'
export { ReactComponent as QIcon_exclamation_outline } from './lib/q_exclamation_outline.svg'
export { ReactComponent as QIcon_exit } from './lib/q_exit.svg'
export { ReactComponent as QIcon_file } from './lib/q_file.svg'
export { ReactComponent as QIcon_filter } from './lib/q_filter.svg'
export { ReactComponent as QIcon_flag } from './lib/q_flag.svg'
export { ReactComponent as QIcon_gift } from './lib/q_gift.svg'
export { ReactComponent as QIcon_gift_teens } from './lib/q_gift_teens.svg'
export { ReactComponent as QIcon_glitter_primary } from './lib/q_glitter_primary.svg'
export { ReactComponent as QIcon_globe } from './lib/q_globe.svg'
export { ReactComponent as QIcon_headphone } from './lib/q_headphone.svg'
export { ReactComponent as QIcon_heart_filled } from './lib/q_heart_filled.svg'
export { ReactComponent as QIcon_heart_outline } from './lib/q_heart_outline.svg'
export { ReactComponent as QIcon_helpcenter } from './lib/q_helpcenter.svg'
export { ReactComponent as QIcon_hint_black } from './lib/q_hint_black.svg'
export { ReactComponent as QIcon_home } from './lib/q_home.svg'
export { ReactComponent as QIcon_information_filled } from './lib/q_information_filled.svg'
export { ReactComponent as QIcon_information_outline } from './lib/q_information_outline.svg'
export { ReactComponent as QIcon_list } from './lib/q_list.svg'
export { ReactComponent as QIcon_lock } from './lib/q_lock.svg'
export { ReactComponent as QIcon_mail } from './lib/q_mail.svg'
export { ReactComponent as QIcon_maximize } from './lib/q_maximize.svg'
// trial_lessons
export { ReactComponent as QIcon_call } from './lib/q_call.svg'
export { ReactComponent as QIcon_fire } from './lib/q_fire.svg'
export { ReactComponent as QIcon_menu_button } from './lib/q_menu_button.svg'
export { ReactComponent as QIcon_menu_button_hamburger } from './lib/q_menu_button_hamburger.svg'
export { ReactComponent as QIcon_menu_button_kebab } from './lib/q_menu_button_kebab.svg'
export { ReactComponent as QIcon_menu_button_meatball } from './lib/q_menu_button_meatball.svg'
export { ReactComponent as QIcon_menu_button_meatball_vertical } from './lib/q_menu_button_meatball_vertical.svg'
export { ReactComponent as QIcon_mic_default } from './lib/q_mic_default.svg'
export { ReactComponent as QIcon_mic_off } from './lib/q_mic_off.svg'
export { ReactComponent as QIcon_minimize } from './lib/q_minimize.svg'
export { ReactComponent as QIcon_openbook } from './lib/q_openbook.svg'
export { ReactComponent as QIcon_outlink } from './lib/q_outlink.svg'
export { ReactComponent as QIcon_paperclip } from './lib/q_paperclip.svg'
export { ReactComponent as QIcon_pause_circle } from './lib/q_pause_circle.svg'
export { ReactComponent as QIcon_pause_filled } from './lib/q_pause_filled.svg'
export { ReactComponent as QIcon_pause_outline } from './lib/q_pause_outline.svg'
export { ReactComponent as QIcon_pause_single } from './lib/q_pause_single.svg'
export { ReactComponent as QIcon_pen } from './lib/q_pen.svg'
export { ReactComponent as QIcon_person } from './lib/q_person.svg'
export { ReactComponent as QIcon_play_filled } from './lib/q_play_filled.svg'
export { ReactComponent as QIcon_play_outline } from './lib/q_play_outline.svg'
export { ReactComponent as QIcon_play_single } from './lib/q_play_single.svg'
export { ReactComponent as QIcon_plus_bold } from './lib/q_plus_bold.svg'
export { ReactComponent as QIcon_plus_default } from './lib/q_plus_default.svg'
export { ReactComponent as QIcon_plus_filled } from './lib/q_plus_filled.svg'
export { ReactComponent as QIcon_plus_outline } from './lib/q_plus_outline.svg'
export { ReactComponent as QIcon_point } from './lib/q_point.svg'
export { ReactComponent as QIcon_printer } from './lib/q_printer.svg'
export { ReactComponent as QIcon_puzzle } from './lib/q_puzzle.svg'
export { ReactComponent as QIcon_question_filled } from './lib/q_question_filled.svg'
export { ReactComponent as QIcon_question_outline } from './lib/q_question_outline.svg'
export { ReactComponent as QIcon_refresh } from './lib/q_refresh.svg'
export { ReactComponent as QIcon_repeat } from './lib/q_repeat.svg'
export { ReactComponent as QIcon_search_bold } from './lib/q_search_bold.svg'
export { ReactComponent as QIcon_search_default } from './lib/q_search_default.svg'
export { ReactComponent as QIcon_send } from './lib/q_send.svg'
export { ReactComponent as QIcon_setting } from './lib/q_setting.svg'
export { ReactComponent as QIcon_share } from './lib/q_share.svg'
export { ReactComponent as QIcon_sidebar } from './lib/q_sidebar.svg'
export { ReactComponent as QIcon_speaker } from './lib/q_speaker.svg'
export { ReactComponent as QIcon_star_filled } from './lib/q_star_filled.svg'
export { ReactComponent as QIcon_star_outline } from './lib/q_star_outline.svg'
export { ReactComponent as QIcon_student } from './lib/q_student.svg'
export { ReactComponent as QIcon_student_custom } from './lib/q_student_custom.svg'
export { ReactComponent as QIcon_thumbs_down } from './lib/q_thumbs_down.svg'
export { ReactComponent as QIcon_thumbs_up } from './lib/q_thumbs_up.svg'
export { ReactComponent as QIcon_thumbs_up_outline } from './lib/q_thumbs_up_outline.svg'
export { ReactComponent as QIcon_ticket } from './lib/q_ticket.svg'
export { ReactComponent as QIcon_timer } from './lib/q_timer.svg'
export { ReactComponent as QIcon_topic } from './lib/q_topic.svg'
export { ReactComponent as QIcon_trash } from './lib/q_trash.svg'
export { ReactComponent as QIcon_trash_outline } from './lib/q_trash_outline.svg'
export { ReactComponent as QIcon_tutor } from './lib/q_tutor.svg'
export { ReactComponent as QIcon_tutor_black } from './lib/q_tutor_black.svg'
export { ReactComponent as QIcon_tutor_blue } from './lib/q_tutor_blue.svg'
export { ReactComponent as QIcon_tutor_default } from './lib/q_tutor_default.svg' // 3.0 tutor default icon
export { ReactComponent as QIcon_tutor_purple } from './lib/q_tutor_purple.svg'

// CAF Beta 3.0
export { ReactComponent as QIcon_caf_beta } from './lib/q_caf_beta.svg'
export { ReactComponent as QIcon_underline_black } from './lib/q_underline_black.svg'
export { ReactComponent as QIcon_video } from './lib/q_video.svg'
// 매주 수업 듣기
export { ReactComponent as QIcon_calendar_exclamation_mark } from './lib/q_calendar_exclamation_mark.svg'
export { ReactComponent as QIcon_status_default } from './lib/q_status_default.svg'
export { ReactComponent as QIcon_status_done } from './lib/q_status_done.svg'
export { ReactComponent as QIcon_status_expired } from './lib/q_status_expired.svg'

export { ReactComponent as QIcon_promotion_badge } from './lib/QIcon_promotion_badge_primary.svg'
export { ReactComponent as QIcon_info } from './lib/q_info.svg'
export { ReactComponent as QIcon_promotion_badge_primary } from './lib/q_promotion_badge.svg'
export { ReactComponent as QIcon_stamp_expired } from './lib/q_stamp_expired.svg'
export { ReactComponent as QIcon_stamp_lastminutes_active } from './lib/q_stamp_lastminute_active.svg'
export { ReactComponent as QIcon_stamp_lastminutes_inactive } from './lib/q_stamp_lastminute_inactive.svg'
export { ReactComponent as QIcon_stamp_lesson } from './lib/q_stamp_lesson.svg'

// caf web
export { ReactComponent as QICon_caf_pause_button } from './lib/q_ai_pause_button.svg'
export { ReactComponent as QICon_caf_play_button } from './lib/q_ai_play_button.svg'
export { ReactComponent as QIcon_arrow_down_gray } from './lib/q_arrow_down_gray.svg'
export { ReactComponent as QIcon_arrow_down_red } from './lib/q_arrow_down_red.svg'
export { ReactComponent as QIcon_arrow_up_gray } from './lib/q_arrow_up_gray.svg'
export { ReactComponent as QIcon_arrow_up_green } from './lib/q_arrow_up_green.svg'
export { ReactComponent as QIcon_caf_MTLD } from './lib/q_caf_MTLD.svg'
export { ReactComponent as QIcon_caf_accuracy } from './lib/q_caf_accuracy.svg'
export { ReactComponent as QIcon_caf_complexity } from './lib/q_caf_complexity.svg'
export { ReactComponent as QImage_caf_empty_mobile_en } from './lib/q_caf_empty_mobile_en.svg'
export { ReactComponent as QImage_caf_empty_mobile_en3 } from './lib/q_caf_empty_mobile_en3.svg'
export { ReactComponent as QImage_caf_empty_mobile_ko } from './lib/q_caf_empty_mobile_ko.svg'
export { ReactComponent as QImage_caf_empty_mobile_ko3 } from './lib/q_caf_empty_mobile_ko3.svg'
export { ReactComponent as QImage_caf_empty_pc_en } from './lib/q_caf_empty_pc_en.svg'
export { ReactComponent as QImage_caf_empty_pc_en3 } from './lib/q_caf_empty_pc_en3.svg'
export { ReactComponent as QImage_caf_empty_pc_ko } from './lib/q_caf_empty_pc_ko.svg'
export { ReactComponent as QImage_caf_empty_pc_ko3 } from './lib/q_caf_empty_pc_ko3.svg'
export { ReactComponent as QIcon_caf_fluency } from './lib/q_caf_fluency.svg'
export { ReactComponent as QIcon_caf_lessons_secondary } from './lib/q_caf_lessons_secondary.svg'
export { ReactComponent as QIcon_caf_list } from './lib/q_caf_list.svg'
export { ReactComponent as QIcon_caf_overview } from './lib/q_caf_overview.svg'
export { ReactComponent as QIcon_caf_pin_active } from './lib/q_caf_pin_active.svg'
export { ReactComponent as QIcon_caf_pin_inactive } from './lib/q_caf_pin_inactive.svg'
export { ReactComponent as QIcon_caf_pronunciation } from './lib/q_caf_pronunciation.svg'
export { ReactComponent as QIcon_location } from './lib/q_location.svg'
export { ReactComponent as QIcon_new_line } from './lib/q_new_line.svg'
export { ReactComponent as QIcon_same_gray } from './lib/q_same_gray.svg'

export { ReactComponent as QIcon_back_5sec } from './lib/q_back_5sec.svg'
export { ReactComponent as QIcon_exclude } from './lib/q_exclude.svg'
export { ReactComponent as QIcon_forward_5sec } from './lib/q_forward_5sec.svg'
export { ReactComponent as QIcon_text } from './lib/q_text.svg'
export { ReactComponent as QIcon_text_with_small_text } from './lib/q_text_with_small_text.svg'

// Study Icons
export { ReactComponent as QIcon_retry_filled } from './lib/q_retry_filled.svg'
export { ReactComponent as QIcon_study_active_gift } from './lib/q_study_active_gift.svg'
export { ReactComponent as QIcon_study_book } from './lib/q_study_book.svg'
export { ReactComponent as QIcon_study_done } from './lib/q_study_done.svg'
export { ReactComponent as QIcon_study_inactive_gift } from './lib/q_study_inactive_gift.svg'

// Preparation Icons
export { ReactComponent as QIcon_arrow_down } from './lib/q_arrow_down.svg'
export { ReactComponent as QIcon_checkbox_selected } from './lib/q_checkbox_selected.svg'
export { ReactComponent as QIcon_checkbox_unselected } from './lib/q_checkbox_unselected.svg'
export { ReactComponent as QIcon_logo } from './lib/q_logo.svg'
export { ReactComponent as QIcon_mic } from './lib/q_mic.svg'
export { ReactComponent as QIcon_noconversation } from './lib/q_noconversation.svg'
export { ReactComponent as QIcon_question_selected } from './lib/q_question_selected.svg'
export { ReactComponent as QIcon_tutor_monitor } from './lib/q_tutor_monitor.svg'

// feedback keywords Icons
export { ReactComponent as QIcon_feedback_depth_of_understanding } from './lib/q_depth_of_understanding.svg'
export { ReactComponent as QIcon_feedback_engaging_conversation } from './lib/q_engaging_conversation.svg'
export { ReactComponent as QIcon_feedback_fluent_delivery } from './lib/q_fluent_delivery.svg'
export { ReactComponent as QIcon_feedback_logical_comprehensive_answer } from './lib/q_logical_comprehensive_answer.svg'
export { ReactComponent as QIcon_feedback_precise_language } from './lib/q_precise_language.svg'
export { ReactComponent as QIcon_feedback_proactive_attitude } from './lib/q_proactive_attitude.svg'
export { ReactComponent as QIcon_feedback_quick_acquisition } from './lib/q_quick_acquisition.svg'
export { ReactComponent as QIcon_feedback_thorough_preparation } from './lib/q_thorough_preparation.svg'
export { ReactComponent as QIcon_timezone_location } from './lib/q_timezone_location.svg'
export { ReactComponent as QIcon_various_expressions } from './lib/q_various_expressions.svg'

// Tutor-apply
export { ReactComponent as QIcon_teens_logo_round } from './lib/q_teens_logo_round.svg'

// Conversational AI
export { ReactComponent as QIcon_ai_speaking_partner } from './lib/q_ai_speaking_partner.svg'
export { ReactComponent as QIcon_volumne } from './lib/q_volume_speaker.svg'

// Teens webview
export { ReactComponent as QIcon_clock_filled } from './lib/q_clock_filled.svg'
export { ReactComponent as QIcon_hammer } from './lib/q_hammer.svg'
export { ReactComponent as QImage_trial_lesson } from './lib/q_trial_lesson_banner_image.svg'
export { ReactComponent as QIcon_tutor_filled } from './lib/q_tutor_filled.svg'

export { ReactComponent as QIcon_smartPhone } from './lib/q_smartphone.svg'
