import apis from '../apis'
import { LocaleType } from '../i18n/config'
import QueryApi, { QueryApiForUser } from './queryApiClass'

//* ============================================================================================================== */
//* landing.ts
//* ============================================================================================================== */

export const landingTutorsQueryApi = new QueryApi({ api: apis.landing.tutors })

interface ILandingMaterialsQueryApiSearchParams {
  locale: LocaleType
}
export const landingMaterialsQueryApi = new QueryApi<void, ILandingMaterialsQueryApiSearchParams>({
  api: apis.landing.materials,
})

//* ============================================================================================================== */
//* user.ts
//* ============================================================================================================== */

export const userQueryApi = new QueryApiForUser()
export const timezoneListApi = new QueryApi<void, void>({ api: apis.profile.timezone })

interface IProfileQueryApiSearchParams {
  child_id?: any
}
export const profileQueryApi = new QueryApi<void, IProfileQueryApiSearchParams>({ api: apis.profile.get })

interface IProfileInfoQueryApiSearchParams {
  child_id?: any
}
export const profileInfoQueryApi = new QueryApi<void, IProfileInfoQueryApiSearchParams>({ api: apis.profile.info })

interface IUpcomingsQueryApiSearchParams {
  child_id?: any
}
export const upcomingsQueryApi = new QueryApi<void, IUpcomingsQueryApiSearchParams>({ api: apis.lessons.upcomings })
interface IPastLessonQueryApi {
  child_id?: any
  start: any
}
export const pastLessonQueryApi = new QueryApi<void, IPastLessonQueryApi>({ api: apis.lessons.review.past })

export const TodoListQueryApi = new QueryApi<void, any>({ api: apis.todo })

export const upcomingsCountQueryApi = new QueryApi({ api: apis.lessons.upcomingsCount })

export const eventPropsUserInfoQueryApi = new QueryApi({ api: apis.auth.eventProps.userInfo })

//* ============================================================================================================== */
//* credit.ts
//* ============================================================================================================== */

export const remainingCreditsQueryApi = new QueryApi({ api: apis.credit.summary })

interface IPointsQueryApiSearchParams {
  country_code: string
}
export const pointsQueryApi = new QueryApi<void, IPointsQueryApiSearchParams>({ api: apis.point.list })

export const referralQueryApi = new QueryApi({
  api: apis.point.referrals,
})

export const creditListQueryApi = new QueryApi({ api: apis.credit.summaryList })

export const myCreditsQueryApi = new QueryApi({ api: apis.credit.my })

//* ============================================================================================================== */
//* geolocation.ts
//* ============================================================================================================== */

export const currentGeoQueryApi = new QueryApi({ api: apis.geolocation })

//* ============================================================================================================== */
//* home.ts
//* ============================================================================================================== */

export const personalizationQueryApi = new QueryApi({ api: apis.personalization })

export const curationListQueryApi = new QueryApi({ api: apis.curation.curations })

export const portalHomeQueryApi = new QueryApi({ api: apis.home.main })

//* ============================================================================================================== */
//* lesson.ts
//* ============================================================================================================== */

interface ILessonPreparationQueryApiPathParams {
  lessonId: number
}
export const lessonPreparationQueryApi = new QueryApi<ILessonPreparationQueryApiPathParams>({
  api: apis.lessons.preparation.get,
})
interface IHomeworkDetailQueryPathParams {
  lessonId: number
}
export const lessonHomeworkDetailQueryApi = new QueryApi<IHomeworkDetailQueryPathParams>({
  api: apis.lessons.lessonHomework,
})

//* ============================================================================================================== */
//* materials.ts
//* ============================================================================================================== */
interface IFilteredMaterialsSearchParams {
  filter: string
}
export const materialsQueryApi = new QueryApi({ api: apis.course.courses })
export const filteredMaterialsQueryApi = new QueryApi<void, IFilteredMaterialsSearchParams>({ api: apis.course.filter })

interface IMaterialsByCategoryIdQueryApiSearchParams {
  course_large_hashtag_id: number
}
export const materialsByCategoryIdQueryApi = new QueryApi<void, IMaterialsByCategoryIdQueryApiSearchParams>({
  api: apis.course.course_category,
})

//* ============================================================================================================== */
//* notifications.ts
//* ============================================================================================================== */

interface INotificationSettingsQueryApiSearchParams {
  child_id: string
}
export const notificationSettingsQueryApi = new QueryApi<void, INotificationSettingsQueryApiSearchParams>({
  api: apis.auth.notificationSettings,
})

//* ============================================================================================================== */
//* products.ts
//* ============================================================================================================== */

interface IProductsListTeensQueryApiSearchParams {
  locale: LocaleType
  country_code: string
}
export const productsListTeensQueryApi = new QueryApi<void, IProductsListTeensQueryApiSearchParams>({
  api: apis.products.list,
})

//* ============================================================================================================== */
//* promotion.ts
//* ============================================================================================================== */

export const promotionGnbQueryApi = new QueryApi({ api: apis.promotion.gnb })

export const promotionPopupQueryApi = new QueryApi({ api: apis.promotion.popup })

//* ============================================================================================================== */
//* purchase.ts
//* ============================================================================================================== */

interface ISuccessPurchaseQueryApiPathParams {
  purchaseId: string
}
export const successPurchaseQueryApi = new QueryApi<ISuccessPurchaseQueryApiPathParams>({ api: apis.purchase.get })

interface IPurchaseHistoryDetailQueryApiPathParams {
  purchaseId: number
}
export const purchaseHistoryDetailQueryApi = new QueryApi<IPurchaseHistoryDetailQueryApiPathParams>({
  api: apis.purchases.detail,
})
interface ILessonBookQueryApiSearchParams {
  id: number
}
export const lessonBookQueryApi = new QueryApi<void, ILessonBookQueryApiSearchParams>({
  api: apis.credit.lessonBook,
})

//* ============================================================================================================== */
//* tutors.ts
//* ============================================================================================================== */

export const myTutorsQueryApi = new QueryApi({ api: apis.tutor.my_type })

//* ============================================================================================================== */
//* auth.ts
//* ============================================================================================================== */

export const childrenQueryApi = new QueryApi({ api: apis.auth.children })

//* ============================================================================================================== */
//* study_report.ts
//* ============================================================================================================== */
export interface StudyUserReportParams {
  userId: number
}
interface StudyUserReportQueryParams {
  key: string
  token: string
}
export const studyReportQueryApi = new QueryApi<StudyUserReportParams, StudyUserReportQueryParams>({
  api: apis.events.userReport,
})

//* ============================================================================================================== */
//* lesson.ts
//* ============================================================================================================== */

export const unassignedLessonsQueryApi = new QueryApi({ api: apis.lessons.unassigned.get })
export const matchingApi = new QueryApi({ api: apis.matching.index })

export const availableTimesForLessonApi = new QueryApi<{ id: any }>({ api: apis.matching.forEdit })

//* ============================================================================================================== */
//* schedule
//* ============================================================================================================== */
export interface ScheduleAtTimeParams {
  year: number
  month: number
  day: number
  hours: number
  minutes: number
  coupon_bundle_id: number
  coupon_type_id: number
}
export const scheduleAtTimeApi = new QueryApi<void, ScheduleAtTimeParams>({ api: apis.matching.scheduleAtTime })

//* ============================================================================================================== */
//* trial.ts
//* ============================================================================================================== */

export const trialStartTimeQueryApi = new QueryApi({ api: apis.lessons.trial.startTimes })

interface ITrialTutorsQueryApiPathParams {
  year: number
  month: number
  day: number
  hours: number
  minutes: number
}
export const trialTutorsQueryApi = new QueryApi<void, ITrialTutorsQueryApiPathParams>({
  api: apis.lessons.trial.tutors,
})

interface ITrialCoursesQueryApiPathParams {
  child_id: number
  english_level: string
  lesson_style: number
}
export const trialCoursesQueryApi = new QueryApi<void, ITrialCoursesQueryApiPathParams>({
  api: apis.lessons.trial.courses,
})

interface ITrialLessonStyleQueryApiPathParams {
  child_id: number
  english_level: string
}
export const trialLessonStyles = new QueryApi<void, ITrialLessonStyleQueryApiPathParams>({
  api: apis.lessons.trial.lessonStyles,
})

//* ============================================================================================================== */
//* contentPrograms.ts
//* ============================================================================================================== */

// GET
interface IContentProgramEnrollmentsQueryApiSearchParams {
  child_id: number
  status: string
}
export const contentProgramEnrollments = new QueryApi<void, IContentProgramEnrollmentsQueryApiSearchParams>({
  api: apis.contentProgram.enrollments,
})

interface IContentProgramEnrolledQueryApiSearchParams {
  sanity_id: string
}
export const contentProgramEnrolled = new QueryApi<void, IContentProgramEnrolledQueryApiSearchParams>({
  api: apis.contentProgram.enrolled,
})

export const contentProgramCredits = new QueryApi({
  api: apis.contentProgram.credits,
})
// POST
export const contentProgramEnroll = new QueryApi({
  api: apis.contentProgram.enroll,
})
